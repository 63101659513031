<template>
  <div style="position: relative;">
    <div class="container-funnel">
      <div class="funnel">
        <div class="container-data-funnel">
          <section class="item-funnel funnel-acessos" v-b-tooltip.hover
            :title="`${$t('chart.checkout_access')}: ${data.access_checkout}`">
          </section>
          <section class="item-funnel funnel-iniciou" v-b-tooltip.html
            :title="`${$t('chart.abandoned_checkout')}: ${data.abandoned_checkout}`">
          </section>
          <section class="item-funnel funnel-tentativa" v-b-tooltip.html
            :title="`${$t('chart.try_payment')}: ${data.try_payment}`">
          </section>
          <section class="item-funnel funnel-realizado end-item-funnel" v-b-tooltip.html
            :title="`${$t('chart.did_payment')}: ${data.payment_made}`">
          </section>
        </div>
      </div>

      <div class="container-label-funnel">
        <div class="row-label-funnel">
          <div class="funnel-opt">
            <div class="mark-label" style="background-color: #0854C5"></div>
            <div class="opt-label">
              <span style="width: 100%">{{ $t('chart.checkout_access') }}</span>
              <InfoTooltip label="Todos os usuários que acessaram o checkout e preencheram nome, email e telefone." />
            </div>
          </div>
          <div class="legendValue d-flex align-items-center">
            <span class="ml-4 value_item_rel text-right">{{ data.access_checkout }}</span>
            <span class="value_item_rel">{{ data.access_checkout_percentage }}</span>
          </div>
        </div>
        <div class="row-label-funnel">
          <div class="funnel-opt">
            <div class="mark-label" style="background-color: #0682FF"></div>
            <div class="opt-label">
              <span style="width: 100%">{{ $t('chart.abandoned_checkout') }}</span>
              <InfoTooltip label="Todos os usuários que preencheram nome, email e telefone e ficaram inativos por mais de 5 minutos." />
            </div>
          </div>
          <div class="legendValue d-flex align-items-center">
            <span class="ml-4 value_item_rel text-right">{{ data.abandoned_checkout }}</span>
            <span class="value_item_rel">{{ data.abandoned_checkout_percentage }}</span>
          </div>
        </div>
        <div class="row-label-funnel">
          <div class="funnel-opt">
            <div class="mark-label" style="background-color: #48C2FF;"></div>
            <div class="opt-label">
              <span style="width: 100%">{{ $t('chart.try_payment')  }}</span>
              <InfoTooltip label="Todos os usuários que clicaram no botão comprar." />
            </div>
          </div>
          <div class="legendValue d-flex align-items-center justify-between">
            <span class="ml-4 value_item_rel text-right">{{ data.try_payment }}</span>
            <span class="value_item_rel">{{ data.try_payment_percentage }}</span>
          </div>
        </div>
        <div class="row-label-funnel">
          <div class="funnel-opt">
            <div class="mark-label" style="background-color: #B5E6FF"></div>
            <div class="opt-label">
              <span style="width: 100%">{{ $t('chart.did_payment') }}</span>
              <InfoTooltip label="Todos os usuários que finalizaram suas compras." />
            </div>
          </div>
          <div class="legendValue d-flex align-items-center">
            <span class="ml-4 value_item_rel text-right">{{ data.payment_made }}</span>
            <span class="value_item_rel">{{ data.payment_made_percentage }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportService from "@/services/resources/ReportService";
import InfoTooltip from "@/components/Seller/Reports/InfoTooltip.vue";
const Report = ReportService.build();

export default {
  name: "FunilChart",
  props: ["filter"],
  components: {
    InfoTooltip,
  },
  data: () => {
    return {
      data: {
        access_checkout: 0,
        abandoned_checkout: 0,
        try_payment: 0,
        payment_made: 0,

        access_checkout_percentage: 0,
        abandoned_checkout_percentage: 0,
        try_payment_percentage: 0,
        payment_made_percentage: 0,

      },
    };
  },
  watch: {
    filter: function () {
      this.read();
    },
  },
  methods: {
    read() {
      let filter = this.filter;
      Report.search({ url: "funnel", ...filter }).then((response) => {
        this.data = Object.assign({}, {
          access_checkout: response.access_checkout,
          abandoned_checkout: response.abandoned_checkout,
          try_payment: response.try_payment,
          payment_made: response.payment_made,

          access_checkout_percentage: response.access_checkout_percentage,
          abandoned_checkout_percentage: response.abandoned_checkout_percentage,
          try_payment_percentage: response.try_payment_percentage,
          payment_made_percentage: response.payment_made_percentage,
        });
      });
    },
  },
};
</script>

<style scoped>
.value_item_rel {
  width: 55px;
  text-align: right;
}

.funnel {
  margin: 0 auto;
  width: 400px;
}

.mark-label {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.container-label-funnel {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .row-label-funnel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }

  .funnel-opt {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .opt-label {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.container-funnel {
  flex-direction: column;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

@media (min-width: 600px) {
  .funnel {
    flex-grow: 0;
    max-width: 66.66667%;
    flex-basis: 66.66667%;
  }
}

.funnel {
  flex-grow: 0;
  max-width: 58.33333%;
  flex-basis: 58.33333%;
}

.container-data-funnel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

section {
  width: 200px;
  height: 0;
  border-radius: 10px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 40px solid;
  margin-top: 5px;
  /* Overlap the sections */
}

section:nth-child(1) {
  border-top-color: #0046B3;
  width: 220px;
  border-left-width: 30px;
  border-right-width: 30px;
}

section:nth-child(2) {
  border-top-color: #007BFF;
  width: 150px;
  border-left-width: 30px;
  border-right-width: 30px;
}

section:nth-child(3) {
  border-top-color: #4CB9FF;
  width: 90px;
  border-radius: 8px;
}

section:nth-child(4) {
  border-top-color: #A3D9FF;
  width: 50px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-radius: 5px;
}




.title-chart-funil {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
}


.container-label-funnel {
  margin: 0 auto;
  width: 100%;
  max-width: 400px;

  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #111827;
  }

  .legendValue span:last-child {
    color: #6B7280;
  }
}
</style>
