<template>
  <div class="mainx">
    <BaseHeader :title="$t('views.seller.reports.text_1428')"></BaseHeader>
    <b-breadcrumb :items="[{ text: 'Dashboard', href: 'dashboard' }, { text: $t('views.seller.reports.text_1428') }]"
      class="bg-white m-0 p-0"></b-breadcrumb>
    <div class="divider"></div>
    <div>
      <FilterExport />
      <div class="container-reports">
        <div class="reports-row">
          <Container title="Faturamento">
            <Revenues :filter="filter" :data="revenues" />
          </Container>
        </div>
        <div class="reports-row">
          <Container :cols="6" title="Liberação de saldo diário"
            subtitle="Previsões de valores disponíveis para saque, dia a dia. Sujeito a alteração por chargebacks e reembolsos."
            link="/relatorios/liberacao_saldo">
            <div class="balance-predict-row">
              <BigNumber :title="`${today} - Hoje`"
                :value="balancePredictions && balancePredictions['sales_grouped_by_date'][todayDate] ? balancePredictions['sales_grouped_by_date'][todayDate]['Valor a liberar'] : 'R$ 0,00'"
                size="lg" />
              <BigNumber :title="`${tomorrow} - Amanhã`"
                :value="balancePredictions && balancePredictions['sales_grouped_by_date'][tomorrowDate] ? balancePredictions['sales_grouped_by_date'][tomorrowDate]['Valor a liberar'] : 'R$ 0,00'"
                :border="true" size="lg" />
            </div>
          </Container>
          <Container :cols="6" title="Potencial de receita para os próximos meses"
            subtitle="Receitas esperadas para os próximos meses. Essa projeção não contempla inadimplência, reembolso e cashback."
            link="/relatorios/potencial_receita">
            <div class="balance-predict-row">
              <BigNumber :title="`${nextMonth}`"
                :value="balancePredictions && balancePredictions['sales_grouped_by_month'][nextMonthDate] ? balancePredictions['sales_grouped_by_month'][nextMonthDate]['total_label'] : 'R$ 0,00'"
                size="lg" />
              <BigNumber :title="`${secondMonth}`"
                :value="balancePredictions && balancePredictions['sales_grouped_by_month'][secondMonthDate] ? balancePredictions['sales_grouped_by_month'][secondMonthDate]['total_label'] : 'R$ 0,00'"
                :border="true" size="lg" />
            </div>
          </Container>
        </div>
        <div class="reports-row">
          <Container :cols="4" title="Tipos de receita"
            subtitle="Quantidade de vendas e porcentagem correspondente de cada tipo de transação.">
            <div class="row">
              <div class="col-6 d-flex flex-column" style="gap: 24px">
                <BigNumber title="Venda única" :value="getSalesPercentage('salesByType', 'transaction')"
                  :subtitle="getSalesTotal('salesByType', 'transaction')" size="md">
                  <template v-slot:tooltip>
                    <InfoTooltip label="Vendas que não contemplam assinaturas." />
                  </template>
                </BigNumber>
                <BigNumber title="Order bump" :value="getSalesPercentage('salesByType', 'bump')"
                  :subtitle="getSalesTotal('salesByType', 'bump')" size="md">
                  <template v-slot:tooltip>
                    <InfoTooltip label="Vendas complementares de produtos no checkout." />
                  </template>
                </BigNumber>
              </div>
              <div class="col-6 d-flex flex-column" style="gap: 24px">
                <BigNumber title="Assinatura" :value="getSalesPercentage('salesByType', 'subscription')"
                  :subtitle="getSalesTotal('salesByType', 'subscription')" size="md">
                  <template v-slot:tooltip>
                    <InfoTooltip
                      label="Cobranças recorrentes por período de tempo. Mensal, trimestral, semestral e anual." />
                  </template>
                </BigNumber>
                <BigNumber title="Upsell" :value="getSalesPercentage('salesByType', 'upsell')"
                  :subtitle="getSalesTotal('salesByType', 'upsell')" size="md">
                  <template v-slot:tooltip>
                    <InfoTooltip label="Vendas complementares de produtos após checkout." />
                  </template>
                </BigNumber>
              </div>
            </div>
          </Container>
          <Container :cols="4" title="Métodos de pagamento"
            subtitle="Quantidade de vendas considerando todos os meios de pagamento disponíveis.">
            <DoughnutData subtitle="Vendas" :labels="salesData ? Object.keys(salesData['salesByMethod']) : []"
              :data="salesData ? Object.values(salesData['salesByMethod']) : []" />
          </Container>
          <Container :cols="4" title="Taxa de conversão"
            subtitle="Quantidade de vendas e porcentagens finalizadas com sucesso em cada meio de pagamento disponível.">
            <div class="row">
              <div class="col-6 d-flex flex-column" style="gap: 24px">
                <BigNumber title="Cartão de crédito" :value="getSalesPercentage('salesByMethod', 'CREDIT_CARD')"
                  :subtitle="getSalesTotal('salesByMethod', 'CREDIT_CARD')" size="md" />
                <BigNumber title="Pix" :value="getSalesPercentage('salesByMethod', 'PIX')"
                  :subtitle="getSalesTotal('salesByMethod', 'PIX')" size="md" />
              </div>
              <div class="col-6 d-flex flex-column" style="gap: 24px">
                <BigNumber title="Paypal" :value="getSalesPercentage('salesByMethod', 'PAYPAL')"
                  :subtitle="getSalesTotal('salesByMethod', 'PAYPAL')" size="md" />
                <BigNumber title="Boleto" :value="getSalesPercentage('salesByMethod', 'BOLETO')"
                  :subtitle="getSalesTotal('salesByMethod', 'BOLETO')" size="md" />
              </div>
            </div>
          </Container>
        </div>
        <div class="reports-row">
          <Container :cols="4" title="Status de vendas únicas"
            subtitle="Status de vendas que não contemplam assinaturas.">
            <DoughnutData subtitle="Vendas únicas"
              :labels="salesData ? Object.keys(salesData['salesTransactionByStatus']) : []"
              :data="salesData ? Object.values(salesData['salesTransactionByStatus']) : []" />
          </Container>
          <Container :cols="4" title="Status de vendas de assinaturas"
            subtitle="Status de vendas recorrentes, com cobranças por período de tempo.">
            <DoughnutData subtitle="Assinaturas"
              :labels="salesData ? Object.keys(salesData['salesSubscriptionByStatus']) : []"
              :data="salesData && Object.values(salesData['salesSubscriptionByStatus']).length ? Object.values(salesData['salesSubscriptionByStatus']) : []" />
          </Container>
          <Container :cols="4" title="Ofertas mais vendidas" subtitle="Seus produtos mais vendidos.">
            <List :data="salesData ? salesData['salesByOffer'] : []" />
          </Container>
        </div>
        <div class="reports-row">
          <Container :cols="4" link="/relatorios/recusas_cc" title="Recusas do cartão de crédito"
            subtitle="Motivos que o cartão de crédito dos usuários recusou as compras dos seus produtos únicos e de assinaturas.">
            <DoughnutData subtitle="Recusadas " :labels="salesData ? Object.keys(salesData['salesRefused']) : []"
              :data="salesData ? Object.values(salesData['salesRefused']) : []" />
          </Container>
          <Container :cols="4" title="Melhores afiliados"
            subtitle="Lista dos seus afiliados com maior número de vendas." :fullWidth="true">
            <Table :filter="filter" :columns="['Nome afiliado', 'Vendas pagas', 'Comissão recebida']"
              :rows="salesUsersPerformance ? Object.values(salesUsersPerformance['affiliates']) : []" />
          </Container>
          <Container :cols="4" title="Melhores coprodutores"
            subtitle="Lista dos seus coprodutores com maior número de vendas." :fullWidth="true">
            <Table :filter="filter" :columns="['Nome coprodutor', 'Vendas pagas', 'Comissão recebida']"
              :rows="salesUsersPerformance ? Object.values(salesUsersPerformance['cosellers']) : []" />
          </Container>
        </div>
        <div class="reports-row" style="max-height: 400px;">
          <Container :cols="12" title="Origem das vendas"
            subtitle="Lista das tags/parâmetros com maior número de vendas." :fullWidth="true">
            <Table :filter="filter" :columns="['Tags', 'Tipo de origem', 'Quantidade de vendas', 'Valor total']"
              :rows="salesMetaTags ? salesMetaTags : []" />
          </Container>
          <!-- <Container title="Resumo do checkout" :cols="4">
            <Checkout :filter="filter" />
          </Container> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Container from "@/components/Seller/Reports/Container.vue";
import Moment from "moment-timezone";
import Checkout from "@/components/Seller/Reports/Checkout.vue";
import Revenues from "@/components/Seller/Reports/Revenues.vue";
import Table from "@/components/Seller/Reports/Table.vue";
import BigNumber from "@/components/Seller/Reports/BigNumber.vue";
import DoughnutData from "@/components/Seller/Reports/DoughnutData.vue";
import List from "@/components/Seller/Reports/List.vue";
import InfoTooltip from "@/components/Seller/Reports/InfoTooltip.vue";

import Contracts from "@/components/Seller/Reports/Contracts.vue";

import ReportService from "@/services/resources/ReportService";
import FilterExport from './FilterExport.vue'

const Report = ReportService.build();
const moment = Moment;

export default {
  name: "Reports",
  components: {
    Checkout,
    Container,
    Contracts,
    Revenues,
    Table,
    BigNumber,
    DoughnutData,
    List,
    InfoTooltip,
    FilterExport
  },
  data: () => {
    return {
      today: moment().format("DD/MM/YYYY"),
      todayDate: moment().format("YYYY-MM-DD"),
      tomorrow: moment().add(1, "days").format("DD/MM/YYYY"),
      tomorrowDate: moment().add(1, "days").format("YYYY-MM-DD"),
      nextMonth: moment().add(1, "months").locale('pt-br').format("MMMM \\d\\e YYYY"),
      nextMonthDate: moment().add(1, "months").format("YYYY-MM"),
      secondMonth: moment().add(2, "months").locale('pt-br').format("MMMM \\d\\e YYYY"),
      secondMonthDate: moment().add(2, "months").format("YYYY-MM"),

      loading: false,
      
      salesData: null,
      balancePredictions: null,
      revenues: null,
      salesUsersPerformance: null,
      salesMetaTags: [],
    };
  },
  computed: {
    filter() {
      return this.$store.getters.getFilters;
    },
  },
  methods: {
    getSalesPercentage(dataKey, method) {
      return this.salesData && this.salesData[dataKey] && this.salesData[dataKey][method]
        ? this.salesData[dataKey][method]['percentage'] + '%'
        : '0,00%';
    },
    getSalesTotal(dataKey, method) {
      return this.salesData && this.salesData[dataKey] && this.salesData[dataKey][method]
        ? this.salesData[dataKey][method]['info']
        : '0 vendas';
    },
    fetchReport() {
      console.log("fetching report")
      this.loading = true;
      Report.search({ url: "sales-availability", ...this.filter, type: "summary" })
        .then((response) => {
          this.balancePredictions = response;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });

      Report.search({ url: "revenues", ...this.filter })
        .then((response) => {
          this.revenues = response;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });

      Report.search({ url: "sales-data", ...this.filter })
        .then((response) => {

          this.salesData = response;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });

      Report.search({ url: "sales-users-performance", ...this.filter })
        .then((response) => {
          this.salesUsersPerformance = response;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });

      Report.search({ url: "sales-meta-tags", ...this.filter })
        .then((response) => {
          this.loading = false;
          this.salesMetaTags = response;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });

    },
  },

};
</script>

<style scoped>
.reports-row {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.container-reports {
  margin-top: 25px;
  gap: 24px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-bottom: 24px;
}

.balance-predict-row {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
}
</style>