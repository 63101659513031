<template>
  <div class="row col-12 p-0">
    <div class="rel-barra col-md-6 col-12 container-chart-contract">
      <StackedBar
        :filter="filter"
        :loading="loadingContract"
        :dataStackedBar="dataStackedBar"
        :title="$t('seller.reports.text_2829')"
        idCanvas="stackedBarContrato"
      >
        <button
          class="btn btn-alternador float-right"
          :class="classStackBarContract.monetary"
          @click="startChartStackedBar('monetary')"
        >
          R$
        </button>
        <button
          class="btn btn-alternador mr-0 float-right"
          :class="classStackBarContract.numeric"
          @click="startChartStackedBar('numeric')"
        >
          Nª
        </button>
      </StackedBar>
    </div>
    <div class="rel-barra col-md-6 col-12">
      <StackedBar
        :filter="filter"
        :loading="loadingTrial"
        :dataStackedBar="dataStackedBarTrial"
        :title="$t('seller.reports.text_2830')"
        idCanvas="stackedBarContratoTrial"
      >
        <button
          class="btn btn-alternador float-right"
          :class="classStackBarTrial.monetary"
          @click="startChartStackedBarTrial('monetary')"
        >
          R$
        </button>
        <button
          class="btn btn-alternador mr-0 float-right"
          :class="classStackBarTrial.numeric"
          @click="startChartStackedBarTrial('numeric')"
        >
          Nª
        </button>
      </StackedBar>
    </div>
  </div>
</template>

<script>
import StackedBar from "../../chart/StackedBar";
import ReportService from "@/services/resources/ReportService";
const Report = ReportService.build();

export default {
  name: "Contracts",
  props: ["filter"],
  components: {
    StackedBar,
  },
  data: () => {
    return {
      loadingContract: false,
      loadingTrial: false,
      dataStackedBar: [],
      dataStackedBarTrial: [],
      classStackBarContract: { monetary: "", numeric: "type-active " },
      classStackBarTrial: { monetary: "", numeric: "type-active " },
    };
  },
  watch: {
    filter: function() {
      this.loadingContract = true;
      this.loadingTrial = true;
      this.startChartStackedBar();
      this.startChartStackedBarTrial();
    },
  },
  methods: {
    startChartStackedBar(type = "numeric") {
      this.loadingContract = true;
      this.classStackBarContract = { monetary: "", numeric: "" };
      this.classStackBarContract[type] = "type-active";

      let filter = Object.assign({}, this.filter, { type });
      let valueTotal = 0;

      if (type === "numeric") {
        valueTotal = 1;
      } else {
        valueTotal = 100;
      }

      Report.search({ url: "contract", ...filter }).then((response) => {
        let labels = [];
        let dataset = [];
        let index = 0;
        for (let item in response) {
          let key = 0;
          labels.push(item);

          item = response[item];

          for (let itemMap in item) {
            itemMap = item[itemMap];

            if (index === 0) {
              dataset.push(
                {
                  type: "bar",
                  label: this.$t("seller.reports.text_2607"),
                  backgroundColor: "#091E3E",
                  data: [itemMap.new ?? 0],
                  stack: key + 1,
                },
                {
                  type: "bar",
                  label: this.$t("seller.reports.text_2608"),
                  backgroundColor: "rgba(0, 228, 160, 0.7)",
                  data: [itemMap.recurrent ?? 0],
                  stack: key + 1,
                },
                {
                  label: this.$t("seller.reports.text_2609"),
                  backgroundColor: "#2a63ab",
                  data: [valueTotal],
                  stack: key + 1,
                }
              );
            } else {
              let findStack = dataset.filter((data) => {
                if (data.stack === key + 1) {
                  return data;
                }
              });
              if (!findStack[0]) {
                continue;
              }
              findStack[0].data.push(itemMap.new ?? 0);
              findStack[1].data.push(itemMap.recurrent ?? 0);
              findStack[2].data.push(valueTotal);
            }
          }
          key++;
          index++;
        }
        this.dataStackedBar = {
          labels: labels,
          datasets: dataset,
          type,
        };
        this.loadingContract = false;
      });
    },
    startChartStackedBarTrial(type = "numeric") {
      this.loadingTrial = true;
      this.classStackBarTrial = { monetary: "", numeric: "" };
      this.classStackBarTrial[type] = "type-active";

      let filter = Object.assign({}, this.filter, { type });
      Report.search({ url: "product-in-trial", ...filter }).then((response) => {
        let labels = [];
        let dataset = [];
        let index = 0;
        for (let item in response) {
          let key = 0;
          labels.push(item);

          item = response[item];

          for (let itemMap in item) {
            itemMap = item[itemMap];

            if (index === 0) {
              dataset.push(
                {
                  type: "bar",
                  label: "Em Trial",
                  backgroundColor: "#091E3E",
                  data: [itemMap.trialing],
                  stack: key + 1,
                },
                {
                  type: "bar",
                  label: "Efetivado",
                  backgroundColor: "#002363",
                  data: [itemMap.paid],
                  stack: key + 1,
                },
                {
                  type: "bar",
                  label: "Cancelado",
                  backgroundColor: "#2A63AB",
                  data: [itemMap.canceled],
                  stack: key + 1,
                },
                {
                  label: `Total`,
                  backgroundColor: "#5592D5",
                  data: [1],
                  stack: key + 1,
                }
              );
            } else {
              let findStack = dataset.filter((data) => {
                if (data.stack === key + 1) {
                  return data;
                }
              });
              if (!findStack[0]) {
                continue;
              }
              findStack[0].data.push(itemMap.trialing);
              findStack[1].data.push(itemMap.paid);
              findStack[2].data.push(itemMap.canceled);
              findStack[3].data.push(1);
            }
          }
          key++;
          index++;
        }
        this.dataStackedBarTrial = {
          labels: labels,
          datasets: dataset,
          type,
        };
        this.loadingTrial = false;
      });
    },
  },
};
</script>

<style scoped>
.container-chart-contract {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  height: 300px;
}

.btn-alternador {
  font-size: 12px;
  outline: none;
  box-shadow: none;
  background-color: #e5e5e5;
  color: #333;
  margin: 0 5px 10px 5px;
  padding: 5px;
  font-weight: 600;
}

.type-active {
  background-color: #333 !important;
  color: #fff;
}
</style>
