<template>
    <div class="data-container">
        <div class="chart-container">
            <DoughnutChart :data="dataComputed" :labels="labelsComputed" :generateLabels="generateLabels"
                :subtitle="subtitle" :key="JSON.stringify(dataComputed)" />
        </div>
        <div class="labels-container">
            <div v-for="label in label_data" class="label">
                <div class="d-flex label-title">
                    <div class="circle" :style="{ backgroundColor: label.fillStyle }"></div>
                    <p>{{ label.label }}</p>
                </div>
                <div class="label-info">
                    <span>{{ label.info }}</span>
                    <span>{{ label.percentage }}</span>
                </div>
            </div>
            <div class="text-center" v-if="data.length < 1">
                <p class="no-data">Não existem dados disponíveis.</p>
            </div>
        </div> 
    </div>
</template>

<script>
import DoughnutChart from "@/components/chart/DoughnutChart.vue";
export default {
    name: "DoughnutData",
    components: {
        DoughnutChart,
    },
    props: ["data", 'labels', 'subtitle'],
    data() {
        return {
            label_data: [],
        };
    },
    computed: {
        labelsComputed() {
            return this.data.map((item) => item.label);
        },
        dataComputed() {
            return this.data.map((item) => parseFloat(item.info));
        },
    },
    methods: {
        generateLabels(chart) {
            this.label_data = this.data.map((data, index) => {
                return {
                    label: data.label,
                    percentage: data.percentage + ' %',
                    info: data.info,
                    fillStyle: chart.data.datasets[0].backgroundColor[index],
                };
            });
        },
    },
};
</script>

<style scoped>
.no-data {
    color: #4B5563;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.data-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 24px;
}

.chart-container {
    position: relative;
    height: 170px;
    width: 170px;
}

.labels-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.label-title {
    display: flex;
    gap: 8px;
    align-items: center;
}

p {
    margin: 0;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #111827;
}

.label {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 100%;


    .circle {
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }

    .label-info {
        display: flex;
        gap: 12px;
        text-align: right;
        min-width: 150px;
        justify-content: space-between;

        span:last-child {
            color: #6B7280;
            min-width: 50px;
        }

        /* first span flex-1 */
        span:first-child {
            flex: 1;
            width: 100%;
        }

        span {
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }
}
</style>