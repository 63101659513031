<template>
  <div class=" p-0">
    <div class="">
      <FunilChart :filter="filter" />
    </div>
  </div>
</template>
<script>
import FunilChart from "../../chart/FunilChart";

export default {
  name: "Checkout",
  props: ["filter"],
   
  components: {
    FunilChart,
  }, 
};
</script>
 
