<template>
  <div class="">
    <div class="container-revenues">
      <BigNumber title="Receita líquida" :value="total_amount" :subtitle="'Quantidade de vendas: ' + total_sales">
        <template v-slot:tooltip>
          <InfoTooltip label="Valor total das vendas realizadas após taxas, descontos e pagamento de comissões." />
        </template>
      </BigNumber>
      <BigNumber title="Usuários únicos" :value="unique_leads" :border="true">
        <template v-slot:tooltip>
          <InfoTooltip
            label="Número de usuários distintos que compraram. Cada usuário é contado apenas uma vez, independentemente de quantas compras ele realizou." />
        </template>
      </BigNumber>
      <BigNumber title="Saldo não disponível para saque" :value="blocked_balance" :border="true">
        <template v-slot:tooltip>
          <InfoTooltip
            label="Valor temporariamente indisponível para saque devido a regras específicas. Verifique as datas de liberação para saber quando poderá retirar os fundos." />
        </template>
      </BigNumber>
      <BigNumber title="Saldo disponível para saque" :value="available_balance" :border="true"
        :color="convertCurrencyToNumber(available_balance) > 0 ? '#16A34A' : (convertCurrencyToNumber(available_balance) == 0 ? '#111827' : 'red')">
        <template v-slot:tooltip>
          <InfoTooltip label="Valor disponível para retirada, pronto para saque." />
        </template>
      </BigNumber>
    </div>
  </div>
</template>

<script>
import BigNumber from "@/components/Seller/Reports/BigNumber.vue";
import InfoTooltip from "@/components/Seller/Reports/InfoTooltip.vue";

export default {
  name: "Revenues",
  props: ["filter", "data"],
  components: {
    BigNumber,
    InfoTooltip,
  },
  data: () => {
    return {
      total_sales: 0,
      total_amount: 0,
      unique_leads: 0,
      blocked_balance: 0,
      available_balance: 0,
    };
  },
  methods: {
    convertCurrencyToNumber(currencyStr) {
      if(!currencyStr) return 0;
      // Remove "R$ " e substitua a vírgula por um ponto
      let numberStr = currencyStr.replace("R$ ", "").replace(",", ".");

      // Converta a string para número
      let number = parseFloat(numberStr);

      // Retorne o número
      return number == 0 ? 0 : number;
    }
  },
  watch: {
    data: {
      handler: function (newData) {
        if (newData) {
          this.total_sales = newData.total_sales;
          this.total_amount = newData.total_amount;
          this.unique_leads = newData.unique_leads;
          this.blocked_balance = newData.blocked_balance;
          this.available_balance = newData.available_balance;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.container-revenues {
  display: flex;
  height: 100px;
  justify-content: space-between;
  max-width: 1300px;
  gap: 24px;
}

.container-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  animation: preload 5s forwards linear infinite alternate;
}
</style>
