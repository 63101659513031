<template>
    <div class="container-list">
        <div v-for="item in data" :key="item.id" class="container-title">
            <h6 class="title-rel m-0 p-0">{{ item.title }}</h6>
            <div class="container-info" v-if="item.subtitle">
                <p class="subtitle-rel m-0 p-0">{{ item.subtitle }}</p>
                <span class="subtitle-rel m-0 p-0">{{ item.info }}</span>
            </div>
        </div>
        <div class="text-center" v-if="data.length < 1">
            <p class="no-data m-0 p-0">Não existem dados disponíveis.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Container",
    props:['data']
}
</script>

<style scoped>
.no-data {
    color: #4B5563;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.container-list{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.container-title {
    display: flex;
    justify-content: space-between;
}

.subtitle-rel {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6B7280;
}

.title-rel{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #111827;
    max-width: 277px;
}

.container-info {
    display: flex;
    flex-direction: column;
    text-align: right;
    p {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #111827;
        flex: 1;
    }

    span {
        font-size: 14px;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        color: #6B7280;
        flex: 1;
        min-width: 80px;
    }
}
</style>